import { render, staticRenderFns } from "./ContextIndicador.vue?vue&type=template&id=69704e4a&"
import script from "./ContextIndicador.vue?vue&type=script&lang=ts&"
export * from "./ContextIndicador.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports