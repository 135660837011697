





















































import { Component, Vue, Ref } from 'vue-property-decorator';
import { BPopover } from 'bootstrap-vue';
import {Saque} from "@/plugins/api";

@Component({})
export default class GerarSaques extends Vue {
  @Ref() public readonly popover!: BPopover;
  public processing = false;

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }

  get popoverId(): string {
    return `gerar-saques-massa`;
  }

  public createSaques(): void {
    Saque.post()
        .then((response) => {
          this.$swal({
            icon: 'success',
            title: response.count + ' saques criados',
          });
          this.$emit('refresh');
        })
        .catch((err) => {
          this.$swal({
            icon: 'error',
            title: err.response.data,
          });
        });
  }
}
