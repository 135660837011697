import Vue from 'vue';
import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    key: 'usuarios.nome',
    label: i18n.tc('colunas.nome.favorecido', 1) as string,
    sortable: true,
  },
  {
    key: 'usuarios.termo_aceito',
    label: i18n.t('indicador.colunas.termo') as string,
    sortable: true,
  },
  {
    key: 'usuarios.telefone_validado',
    label: i18n.t('indicador.colunas.telefone') as string,
    thStyle: { width: '100px' },
    tdClass: 'text-center text-capitalize',
    sortable: true,
  },
  {
    key: 'recebidos.indicador',
    label: i18n.t('comissoes.colunas.indicador') as string,
    thStyle: { width: '100px' },
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'recebidos.gestor',
    label: i18n.t('comissoes.colunas.gestor') as string,
    thStyle: { width: '100px' },
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'dados_bancarios.situacao',
    label: i18n.t('indicador.banco.situacao') as string,
    thStyle: { width: '80px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'comissoes.total',
    label: i18n.tc('colunas.valor.saque', 2) as string,
    thStyle: { width: '120px' },
    sortable: true,
  },
  {
    key: 'comissoes.quantidade',
    label: i18n.t('colunas.total.comissoes') as string,
    thStyle: { width: '110px' },
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'data_solicitacao',
    label: i18n.tc('colunas.data.solicitacao', 2) as string,
    thStyle: { width: '100px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'data_pagamento',
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    thStyle: { width: '100px' },
    thClass: 'text-center',
    sortable: true,
  },
  {
    key: 'situacao',
    label: i18n.t('colunas.situacao') as string,
    thStyle: { width: '80px' },
    thClass: 'text-center',
    sortable: true,
  },
];

const situacoes = [
  {
    text: i18n.t('saques.situacoes.todos') as string,
    value: '',
    variant: '',
  },
  {
    text: i18n.t('saques.situacoes.pendente') as string,
    value: 'pendente',
    variant: 'warning',
  },
  {
    text: i18n.t('saques.situacoes.suspenso') as string,
    value: 'suspenso',
    variant: 'secondary',
  },
  {
    text: i18n.t('saques.situacoes.rejeitado') as string,
    value: 'rejeitado',
    variant: 'danger',
  },
  {
    text: i18n.t('saques.situacoes.pago') as string,
    value: 'pago',
    variant: '',
  },
];

const situacoesBancarias = [
  {
    text: i18n.t('indicador.banco.situacoes.pendente') as string,
    value: 'pendente',
    variant: 'warning',
  },
  {
    text: i18n.t('indicador.banco.situacoes.rejeitado') as string,
    value: 'rejeitado',
    variant: 'danger',
  },
  {
    text: i18n.t('indicador.banco.situacoes.verificado') as string,
    value: 'verificado',
    variant: 'success',
  },
];

const columnsFilter: IFiltroField[] = [
  {
    id: 'usuarios.id',
    label: i18n.t('colunas.codigo.favorecido') as string,
    type: 'id',
  },
  {
    id: 'usuarios.nome',
    label: i18n.tc('colunas.nome.favorecido', 2) as string,
    type: 'text',
  },
  {
    id: 'comissoes.total',
    label: i18n.tc('colunas.valor.saque', 2) as string,
    type: 'number',
  },
  {
    id: 'comissoes.quantidade',
    label: i18n.t('colunas.total.indicacoes') as string,
    type: 'number',
  },
  {
    id: 'dados_bancarios.situacao',
    label: i18n.t('indicador.banco.situacao') as string,
    type: 'multi-enum',
    enum: situacoesBancarias.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    id: 'saques.situacao',
    label: i18n.t('colunas.situacao') as string,
    type: 'enum',
    enum: situacoes.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    id: 'saques.data_solicitacao',
    label: i18n.tc('colunas.data.solicitacao', 2) as string,
    type: 'date',
  },
  {
    id: 'saques.data_pagamento',
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    type: 'date',
  },
];

export { columns, situacoes, columnsFilter };
